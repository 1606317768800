import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import Img from "gatsby-image"
import styled from "styled-components"
import Tag from "./Tag"
//styled icons
import { CalendarAlt } from "styled-icons/fa-regular"
import { Clock } from "styled-icons/fa-regular"

const Container = styled.div`
  padding: ${rhythm(1 / 2)};
  margin-bottom: ${rhythm(1)};
  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.cardBg};
  box-shadow: ${({ theme }) => theme.baseBoxShadow};
  align-self: start;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    ${({ theme }) => theme.baseThemeTransition};
  &:hover {
    /*color: #5d6778;
    background: #f7f8fc;*/
    transform: translateY(-10px);
    box-shadow: ${({ theme }) => theme.hoveredBoxShadow};
  }
`
const StyledLink = styled(Link)`
  color: inherit;
  &:hover {
    color: inherit;
    opacity: 0.8;
  }
`
const StyledImage = styled(Img)`
  height: ${rhythm(10)};
  /*${Container}:hover & {
    opacity: 0.75;
  }*/
`
const Title = styled.h3`
  margin-top: ${rhythm(1)};
  margin-bottom: ${rhythm(1 / 4)};
`

const FooterStyled = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-style: italic;
  font-size: 1.1rem;
`
const StyledClockIcon = styled(Clock)`
  display: inline-block;
  width: 1em;
  margin-right: 3px;
`
const StyledCalendarAlt = styled(CalendarAlt)`
  display: inline-block;
  width: 1em;
  margin-right: 6px;
`

const PostCard = props => {
  const { data: post } = props

  //check if there is a featured image
  const isFeaturedImage = post.frontmatter.featuredImage
  const featuredImageFluid = isFeaturedImage
    ? isFeaturedImage.childImageSharp.fluid
    : null

  return (
    <Container className="card">
      <StyledLink to={post.fields.slug} aria-label="Lire l'article">
        <header>
          {isFeaturedImage && <StyledImage fluid={featuredImageFluid} />}
          <Title>{post.frontmatter.title}</Title>
        </header>
        <section>
          <p
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
          />
        </section>
      </StyledLink>
      <section>
        {post.frontmatter.tags &&
          post.frontmatter.tags.map(tag => {
            return <Tag tag={tag} key={tag} />
          })}
      </section>
      <StyledLink to={post.fields.slug} aria-label="Lire l'article">
        <FooterStyled>
          <small>
            <StyledClockIcon /> {post.timeToRead} min de lecture
          </small>
          <small>
            <StyledCalendarAlt />
            {post.frontmatter.date}
          </small>
        </FooterStyled>
      </StyledLink>
    </Container>
  )
}

export default PostCard
