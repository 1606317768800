import React from "react"
import { Link } from "gatsby"
//utilities
import { rhythm } from "../utils/typography"
import styled from "styled-components"
import kebabCase from "lodash/kebabCase"
/**
 * Styling with Styled Components
 */
const TagContainer = styled.div`
  background-color: ${({ theme, tagContainerColor }) =>
    theme[tagContainerColor]};
  color: black;
  //text-shadow: 1px 1px 7px rgba(0, 0, 0, 1);
  display: inline-flex;
  padding: ${rhythm(1 / 10)} ${rhythm(1 / 4)};
  border-radius: 10px;
  margin-bottom: ${rhythm(1 / 2)};
  margin-right: ${rhythm(1 / 4)};
  transition: transform 0.2s ease, ${({ theme }) => theme.baseThemeTransition};

  &:hover {
    box-shadow: ${({ theme }) => theme.baseBoxShadow};
    transform: translateY(-4px);
    color: white;
  }
  & :active {
    box-shadow: none;
    transform: translateY(0px);
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  box-shadow: none;
  color: #2d3436;
`

const Tag = props => {
  const { tag } = props
  const tagKebabCase = kebabCase(tag)

  return (
    <StyledLink to={`/tags/${tagKebabCase}`}>
      <TagContainer tagContainerColor={tagKebabCase}>{tag}</TagContainer>
    </StyledLink>
  )
}

export default Tag
